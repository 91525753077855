import { FormikProps } from "formik"
import classnames from "classnames"
import React from "react"
import { requestJson } from "./request-json"

export type FormikValues<Bag> = Bag extends FormikProps<any> ? Bag["values"] : never

export function FormHelpers<Bag extends FormikProps<any>>(bag: Bag) {
  const bind = (field: keyof FormikValues<Bag>) => {
    const value = bag.values[field] || ""
    return {
      className: classnames("input", bag.errors[field] && "validate invalid"),
      onChange: bag.handleChange,
      onBlur: bag.handleBlur,
      value,
      name: field as string,
      id: field as string,
    }
  }

  const Label: React.SFC<{ htmlFor: keyof FormikValues<Bag>; static?: boolean }> = i => {
    let isInactive =
      bag.values[i.htmlFor] === "" ||
      bag.values[i.htmlFor] === null ||
      bag.values[i.htmlFor] === undefined
    if (i.static) isInactive = false
    return (
      <label className={classnames(!isInactive && "active")} htmlFor={i.htmlFor as string}>
        {i.children}
      </label>
    )
  }

  const InputFeedbackFor = ({ field }: { field: keyof FormikValues<Bag> }) => {
    return bag.touched[field] && bag.errors[field] ? (
      <p className="help is-danger">{bag.errors[field]}</p>
    ) : null
  }

  return { bind, InputFeedbackFor, Label }
}

export const cepBindGen = (bind: any, bag: any, cb: (s: string) => any) => (field: string) => {
  let prevbind = bind(field)
  const onChange = (ev: any) => {
    //console.log("ev", ev.target.value, "val", bag.values[field])
    let curr = ev.target.value as string
    if (curr.length > 5 && String(bag.values[field]).length <= 5) {
      ev.target.value = curr.substr(0, 5) + "-" + curr.substr(5)
    }
    prevbind.onChange(ev)
  }
  const onBlur = (ev: any) => {
    let value = bag.values[field] || ""
    cb(value)
    return prevbind.onBlur && prevbind.onBlur(ev)
  }
  let out = {
    ...prevbind,
    onChange,
    onBlur,
  }
  return out
}

export const addrBindGen = (bind: any, bag: any, callback: (i: any) => any) => (field: string) => {
  let prevBind = bind(field)
  const onBlur = async (ev: any) => {
    let out = prevBind.onBlur(ev)
    try {
      let resp = await requestJson<{ valid: boolean; lat: number; long: number }>(
        "/api/nota-fiscal/geocheck",
        {
          endereco: bag.values[field] || "",
        }
      )
      callback(resp)
    } catch (err) {
      callback({ valid: false })
    }
    return out
  }
  return {
    ...prevBind,
    onBlur,
  }
}
