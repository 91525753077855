import React from "react"

export class ErrorCard extends React.PureComponent<{ message: string }> {
  componentDidMount() {
    if (this.props.message) {
      M.toast({ html: this.props.message })
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.message !== this.props.message) {
      M.toast({ html: this.props.message })
    }
  }

  render() {
    let i = this.props
    return (
      (i.message && (
        <div className="row">
          <div className="col s12">
            <div className="card blue-grey darken-1">
              <div className="card-content white-text">
                {/* <span className="card-title">Card Title</span> */}
                <p>{i.message}</p>
              </div>
            </div>
          </div>
        </div>
      )) ||
      null
    )
  }
}
