import React from "react"
import { url } from "@common/url"
import { ErrorCard } from "@common/components/error-card"
import Router from "next/router"
import { Formik, FormikProps } from "formik"
import { requestJson } from "@common/request-json"
import { formatError } from "@proerd/nextpress-client/lib/error"
import * as yup from "yup"
import { FormHelpers } from "@common/form-helpers"
import Head from "next/head"

export class Root extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("user_token")) Router.push("/dashboard")
  }

  render() {
    return (
      <>
        <style jsx>{`
          body {
            background-color: #1a237e;
            color: white;
            text-align: center;
          }
        `}</style>
        <Head>
          <title>SELAD Transportes</title>
        </Head>
        <div className="container" id="login-page">
          <div className="section logo-section">
            <img
              src={url("/static/images/logo-selad-transportes.png")}
              alt="SELAD - Transportamos Confiança"
              className="logo"
            />
          </div>
          <div className="section form-contain row">
            <LoginForm />
          </div>
        </div>
      </>
    )
  }
}

const initial = {
  username: "",
  password: "",
}

class LoginForm extends React.Component {
  state = { error: "" }

  render() {
    return (
      <Formik
        initialValues={initial}
        validationSchema={yup.object({
          username: yup
            .string()
            .min(3)
            .required(),
          password: yup
            .string()
            .min(3)
            .required(),
        })}
        onSubmit={async (values: any) => {
          try {
            let response = await requestJson<{ userInfo: T.User; token: string }>(
              "/api/user/login",
              {
                username: values.username,
                password: values.password,
              }
            )
            localStorage.setItem("user_token", response.token)
            localStorage.setItem("user_info", JSON.stringify(response.userInfo))
            window.location.pathname = "/dashboard"
          } catch (err) {
            this.setState({ error: formatError(err).error.message })
          }
        }}
        render={(bag: any) => <LoginForm_ bag={bag} error={this.state.error} />}
      />
    )
  }
}

const LoginForm_ = (i: { bag: FormikProps<typeof initial>; error: string }) => {
  const { bind, InputFeedbackFor, Label } = FormHelpers(i.bag)
  return (
    <form className="col s12" onSubmit={i.bag.handleSubmit}>
      {i.error && <ErrorCard message={i.error} />}
      <div className="row">
        <div className="input-field col s12">
          {/* <i className="material-icons prefix">account_box</i> */}
          <Label htmlFor="username">Usuário</Label>
          <input id="username" type="text" className="validate" {...bind("username")} />
          <InputFeedbackFor field="username" />
        </div>
        <div className="input-field col s12">
          {/* <i className="material-icons prefix">lock</i> */}
          <Label htmlFor="password">Senha</Label>
          <input id="password" type="password" className="validate" {...bind("password")} />
          <InputFeedbackFor field="password" />
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <button type="submit" className="waves-effect waves-light btn-large">
            <i className="material-icons right">arrow_forward</i>
            Entrar
          </button>
        </div>
      </div>
    </form>
  )
}
